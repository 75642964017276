import { registerControllers } from 'stimulus-vite-helpers'
import { stimulus } from '~/init'
import AutoSubmit from '@stimulus-components/auto-submit'
import Clipboard from '@stimulus-components/clipboard'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import HwComboboxController from '@josefarias/hotwire_combobox'

stimulus.register('auto-submit', AutoSubmit)
stimulus.register('clipboard', Clipboard)
stimulus.register('textarea-autogrow', TextareaAutogrow)
stimulus.register('hw-combobox', HwComboboxController)

const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(stimulus, controllers)
