import { Controller } from '@hotwired/stimulus'
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

import { stimulus } from '~/init'

const unusualTextAlignTypes = ['center', 'right', 'justify']

// FIXME.
// Seperate the controller to a new entry point. It depends on draft-js and draft-js-export-html.
// React is not used in this project. It's better to use a separate entry point for this controller.
export default class Draftjs extends Controller {
  connect () {
    this.element.innerHTML = this.#convertToHTML()
  }

  #convertToHTML () {
    if (this.element.dataset.rawContent) {
      const rawContent = JSON.parse(this.element.dataset.rawContent)
      const rawContentState = convertFromRaw(rawContent)

      const options = {
        blockStyleFn: (block) => {
          if (block) {
            const blockType = block.getType()
            const style = {}

            if (unusualTextAlignTypes.includes(blockType)) {
              style.textAlign = blockType
            }

            return { style }
          }
        },
        inlineStyleFn: (styles) => {
          const key = 'CUSTOM_COLOR_'
          const color = styles.filter((value) => value.startsWith(key)).first()

          if (color) {
            return {
              element: 'span',
              style: {
                color: color.replace(key, '')
              }
            }
          }
        },
        entityStyleFn: (entity) => {
          const entityType = entity.get('type').toLowerCase()
          if (entityType === 'link') {
            const data = entity.getData()
            return {
              element: 'a',
              attributes: {
                href: data.url,
                'data-external-link': ''
              }
            }
          }
        }
      }

      return stateToHTML(rawContentState, options)
    }
  }
}

stimulus.register('draftjs', Draftjs)
