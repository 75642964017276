export function transitionSync(node, show, onDone) {
  headlessui_transition(node, {
    prepare() {
      if (show) {
        node.setAttribute("data-transition-closed", "");
        node.setAttribute("data-transition-enter", "");
        node.removeAttribute("data-transition-leave");
      } else {
        node.setAttribute("data-transition-leave", "");
        node.removeAttribute("data-transition-enter");
      }
    },
    run() {
      if (show) {
        node.removeAttribute("data-transition-closed");
      } else {
        node.setAttribute("data-transition-closed", "");
      }
    },
    done() {
      node.removeAttribute("data-transition-enter");
      node.removeAttribute("data-transition-leave");
      onDone?.();
    },
  });
}

export async function transition(node, show) {
  return new Promise((resolve) => transitionSync(node, show, resolve));
}

// Taken from Headless UI's useTransition hook
// https://github.com/tailwindlabs/headlessui/blob/ca6a455a3aec8682033dd5595c10abd4330fd4f9/packages/%40headlessui-react/src/hooks/use-transition.ts#L187
function headlessui_transition(node, { prepare, run, done }) {
  let d = disposables();

  // Prepare the transitions by ensuring that all the "before" classes are
  // applied and flushed to the DOM.
  prepareTransition(node, { prepare });

  // This is a workaround for a bug in all major browsers.
  //
  // 1. When an element is just mounted
  // 2. And you apply a transition to it (e.g.: via a class)
  // 3. And you're using `getComputedStyle` and read any returned value
  // 4. Then the `transition` immediately jumps to the end state
  //
  // This means that no transition happens at all. To fix this, we delay the
  // actual transition by one frame.
  d.nextFrame(() => {
    // Wait for the transition, once the transition is complete we can cleanup.
    // This is registered first to prevent race conditions, otherwise it could
    // happen that the transition is already done before we start waiting for
    // the actual event.
    d.add(waitForTransition(node, done));

    // Initiate the transition by applying the new classes.
    run();
  });

  return d.dispose;
}

function waitForTransition(node, _done) {
  let done = once(_done);
  let d = disposables();

  if (!node) return d.dispose;

  // Safari returns a comma separated list of values, so let's sort them and take the highest value.
  let { transitionDuration, transitionDelay } = getComputedStyle(node);

  let [durationMs, delayMs] = [transitionDuration, transitionDelay].map(
    (value) => {
      let [resolvedValue = 0] = value
        .split(",")
        // Remove falsy we can't work with
        .filter(Boolean)
        // Values are returned as `0.3s` or `75ms`
        .map((v) => (v.includes("ms") ? parseFloat(v) : parseFloat(v) * 1000))
        .sort((a, z) => z - a);

      return resolvedValue;
    }
  );

  let totalDuration = durationMs + delayMs;

  if (totalDuration !== 0) {
    if (process.env.NODE_ENV === "test") {
      let dispose = d.setTimeout(() => {
        done();
        dispose();
      }, totalDuration);
    } else {
      let disposeGroup = d.group((d) => {
        // Mark the transition as done when the timeout is reached. This is a fallback in case the
        // transitionrun event is not fired.
        let cancelTimeout = d.setTimeout(() => {
          done();
          d.dispose();
        }, totalDuration);

        // The moment the transitionrun event fires, we should cleanup the timeout fallback, because
        // then we know that we can use the native transition events because something is
        // transitioning.
        d.addEventListener(node, "transitionrun", (event) => {
          if (event.target !== event.currentTarget) return;
          cancelTimeout();

          d.addEventListener(node, "transitioncancel", (event) => {
            if (event.target !== event.currentTarget) return;
            done();
            disposeGroup();
          });
        });
      });

      d.addEventListener(node, "transitionend", (event) => {
        if (event.target !== event.currentTarget) return;
        done();
        d.dispose();
      });
    }
  } else {
    // No transition is happening, so we should cleanup already. Otherwise we have to wait until we
    // get disposed.
    done();
  }

  return d.dispose;
}

function prepareTransition(node, { prepare }) {
  // let previous = node.style.transition;
  // Force cancel current transition
  // node.style.transition = "none";
  prepare();
  // Trigger a reflow, flushing the CSS changes
  // node.offsetHeight;
  // Reset the transition to what it was before
  // node.style.transition = previous;
}

function once(cb) {
  let state = { called: false };

  return (...args) => {
    if (state.called) return;
    state.called = true;
    return cb(...args);
  };
}

/**
 * Disposables are a way to manage event handlers and functions like
 * `setTimeout` and `requestAnimationFrame` that need to be cleaned up when they
 * are no longer needed.
 *
 *
 * When you register a disposable function, it is added to a collection of
 * disposables. Each disposable in the collection provides a `dispose` clean up
 * function that can be called when it's no longer needed. There is also a
 * `dispose` function on the collection itself that can be used to clean up all
 * pending disposables in that collection.
 */
export function disposables() {
  let _disposables = [];

  let api = {
    addEventListener(element, name, listener, options) {
      element.addEventListener(name, listener, options);
      return api.add(() =>
        element.removeEventListener(name, listener, options)
      );
    },

    requestAnimationFrame(...args) {
      let raf = requestAnimationFrame(...args);
      return api.add(() => cancelAnimationFrame(raf));
    },

    nextFrame(...args) {
      return api.requestAnimationFrame(() => {
        return api.requestAnimationFrame(...args);
      });
    },

    setTimeout(...args) {
      let timer = setTimeout(...args);
      return api.add(() => clearTimeout(timer));
    },

    microTask(...args) {
      let task = { current: true };
      microTask(() => {
        if (task.current) {
          args[0]();
        }
      });
      return api.add(() => {
        task.current = false;
      });
    },

    style(node, property, value) {
      let previous = node.style.getPropertyValue(property);
      Object.assign(node.style, { [property]: value });
      return this.add(() => {
        Object.assign(node.style, { [property]: previous });
      });
    },

    group(cb) {
      let d = disposables();
      cb(d);
      return this.add(() => d.dispose());
    },

    add(cb) {
      // Ensure we don't add the same callback twice
      if (!_disposables.includes(cb)) {
        _disposables.push(cb);
      }

      return () => {
        let idx = _disposables.indexOf(cb);
        if (idx >= 0) {
          for (let dispose of _disposables.splice(idx, 1)) {
            dispose();
          }
        }
      };
    },

    dispose() {
      for (let dispose of _disposables.splice(0)) {
        dispose();
      }
    },
  };

  return api;
}

function microTask(cb) {
  if (typeof queueMicrotask === "function") {
    queueMicrotask(cb);
  } else {
    Promise.resolve()
      .then(cb)
      .catch((e) =>
        setTimeout(() => {
          throw e;
        })
      );
  }
}
