import { Controller } from "@hotwired/stimulus";
import { stimulus } from "~/init";
import { transition } from "~/utils/transition";

const CONTROLLER_NAME = "download-app-modal";

export default class ModalController extends Controller {
  connect() {
    this.options = this.element.dataset;

    this.modal = new window.Modal(
      this.element,
      {
        placement: this.options.placement,
        backdrop: "static",
        backdropClasses: this.options.backdropClass || "",
        closable: this.options.closable !== "false",
      },
      {
        id: "modalContainer",
        override: false,
      }
    );

    // Set onOutsideClick handler
    if (this.options.backdrop !== "static") {
      this.modal._targetEl.setAttribute(
        "data-action",
        (this.modal._targetEl.getAttribute("data-action") || "") +
          ` click->${CONTROLLER_NAME}#handleOutsideClick`
      );
    }
  }

  open() {
    this.modal.show();
    this.#transition(true);
  }

  async close() {
    await this.#transition(false);
    this.modal.hide();

    if (this.options.inline !== "true") {
      this.disconnect();
    }
  }

  handleOutsideClick(e) {
    if (
      e.target === this.modal._targetEl ||
      (e.target === this.modal._backdropEl && this.modal.isVisible())
    ) {
      this.close();
    }
  }

  #transition(show) {
    const waitForModal = async () => {
      const node = this.modal._targetEl;

      await transition(node, show);
    };

    const waitForBackdrop = async () => {
      const backdrop = this.modal._backdropEl;
      if (!backdrop) return;

      await transition(backdrop, show);
    };

    return Promise.all([waitForModal(), waitForBackdrop()]);
  }

  disconnect() {
    if (this.options.inline !== "true")
      ModalController.modalTurboFrame.src = null;
    this.observer?.disconnect();
    this.element.src = null;
    this.element.remove();
    this.modal.destroyAndRemoveInstance();
  }

  static get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}

stimulus.register(CONTROLLER_NAME, ModalController);
