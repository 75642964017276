import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    this.pages = document.querySelectorAll("[data-content-role='page']")
    this.links = document.querySelectorAll("[data-content-nav-link]")
    this.horizontalNavList = document.querySelector("[data-content-role='horizontal_nav_list']")
    this.horizontalNavItems = document.querySelectorAll("[data-horizontal-nav-item]")

    if (this.pages && this.links) {
      this.handleScroll = this.handleScroll.bind(this)
      window.addEventListener('scroll', this.handleScroll)
      this.handleScroll()
    }
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    this.pages.forEach((page) => {
      const top = window.scrollY
      const offset = page.offsetTop - 150
      const height = page.offsetHeight
      const id = page.getAttribute('id')

      if (top >= offset && top < offset + height) {
        this.removeActiveClass(this.links)
        this.addActiveClass(`[data-content-nav-link='${id}']`)

        if (this.horizontalNavList && this.horizontalNavItems) {
          this.updateHorizontalNav(this.horizontalNavList, this.horizontalNavItems, id)
        }
      }
    })
  }


  toggle() {
    this.toggleClass(this.containerTarget, 'hidden')
    this.toggleClass(document.body, 'overflow-hidden')
  }

  show() {
    this.removeClass(this.containerTarget, 'hidden')
    this.addClass(document.body, 'overflow-hidden')
  }

  close() {
    this.addClass(this.containerTarget, 'hidden')
    this.removeClass(document.body, 'overflow-hidden')
  }

  updateHorizontalNav(listElement, items, pageId) {
    this.removeActiveClass(items)
    this.addActiveClass(`[data-horizontal-nav-item='${pageId}']`)

    const activeItemElement = document.querySelector(`[data-horizontal-nav-item='${pageId}']`)
    const activeItemRect = activeItemElement.getBoundingClientRect()
    const listRect = listElement.getBoundingClientRect()
    const offsetLeft = activeItemRect.left - listRect.left + listElement.scrollLeft
    const scrollLeft = offsetLeft - (listElement.clientWidth / 2) + (activeItemElement.clientWidth / 2)

    listElement.scroll({
      left: scrollLeft,
    })
  }

  removeActiveClass(elements) {
    elements.forEach((element) => {
      element.classList.remove('active')
    })
  }

  addActiveClass(selector) {
    const element = document.querySelector(selector)
    if (element) {
      element.classList.add('active')
    }
  }

  toggleClass(element, className) {
    element.classList.toggle(className)
  }

  removeClass(element, className) {
    element.classList.remove(className)
  }

  addClass(element, className) {
    element.classList.add(className)
  }

  scrollToElementById(elementId) {
    const element = document.getElementById(elementId)

    if (element) {
        element.scrollIntoView()
    }
  }

  handleNavLinkClick(e) {
    e.preventDefault()
    const pageId = e.target.getAttribute('data-content-nav-link')
    this.scrollToElementById(pageId)
  }

  handleDrawerLinkClick(e) {
    this.handleNavLinkClick(e)
    this.close()
  }
}
