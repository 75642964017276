import { Controller } from '@hotwired/stimulus'

const DEFAULT_IMAGE_STYLES = {
  position: 'absolute',
  left: 0,
  top: 0,
  minHeight: '100%',
  minWidth: '100%',
}

export default class extends Controller {
  applyStylesToElement(element, stylesObject) {
    for (const property in stylesObject) {
        if (stylesObject.hasOwnProperty(property)) {
            element.style[property] = stylesObject[property]
        }
    }
  }

  calculateShift(dimensionRatio, containerSize, imageSize, focus) {
    const containerCenter = containerSize / 2
    const scaledImage = imageSize / dimensionRatio
    const scaledFocus = scaledImage * focus

    if (scaledFocus > scaledImage - containerCenter) {
      return (scaledImage - containerSize) * -1
    }

    if (scaledFocus < containerCenter) {
      return 0
    }

    return (scaledFocus - containerCenter) * -1
  }

  computeImageStyles() {
    const img = this.element
    const coords = JSON.parse(img.getAttribute("data-coords"))
    const container = img.closest("[data-content-role='image_container']")

    img.removeAttribute("style");

    if (coords?.length !== 2) {
      return { height: '100%', width: '100%', objectFit: 'cover' }
    }

    if (img && container) {
      const naturalWidth = Number(img.getAttribute("data-width"))
      const naturalHeight = Number(img.getAttribute("data-height"))
      const { height, width } = container.getBoundingClientRect()

      if (!naturalHeight || !naturalWidth || !height || !width) {
        this.applyStylesToElement(img, { display: 'none' })
        return
      }

      const style = {
        ...DEFAULT_IMAGE_STYLES,
      }

      const widthRatio = naturalWidth / width
      const heightRatio = naturalHeight / height

      if (widthRatio > heightRatio) {
        style.maxHeight = '100%'
        style.maxWidth = `${naturalWidth / heightRatio}px`
        style.left = `${this.calculateShift(heightRatio, width, naturalWidth, coords[0] / 100)}px`
      } else {
        style.maxWidth = '100%'
        style.maxHeight = 'initial'
        style.top = `${this.calculateShift(widthRatio, height, naturalHeight, coords[1] / 100)}px`
      }

      this.applyStylesToElement(img, style)
    }
  }

  connect() {
    const img = this.element
    const container = img.closest("[data-content-role='image_container']")
    this.computeImageStyles()

    window.addEventListener('resize', this.computeImageStyles.bind(this))

    const observer = new ResizeObserver(() => {
      this.computeImageStyles()
    });

    observer.observe(container)
  }
}
