import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'autofocus']

  connect () {
    const options = {
      placement: 'center-center',
      onShow: () => {
        this.dispatch('show')

        if (this.hasAutofocusTarget) {
          this.#autofocus()
        }
      }
    }

    this.modal = new window.Modal(this.contentTarget, options)
  }

  open () {
    this.modal.show()
  }

  close () {
    this.modal.hide()
  }

  #autofocus () {
    setTimeout(() => {
      this.autofocusTarget.focus()
    }, 100)
  }
}
